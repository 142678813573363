@import '../../../styles/customMediaQueries.css';

.enquirySuccessModal {
  & .shareWith {
    margin-top: 30px;

    @media (--viewportMedium) {
      margin-top: 0px;
    }

    & > h1 {
      font-size: 20px;
      color: var(--matterColorGrey);
      line-height: 100%;

      @media (--viewportMedium) {
        font-size: 30px;
      }
    }

    & > ul {
      margin: 0px;

      & > li {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--matterColorNegative);

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 0;
        }

        & > button {
          width: 100%;
          text-align: left;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:hover {
            & .leftIcon {
              color: var(--marketplaceColor);
            }
          }

          & .leftIcon {
            & > svg {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

.shareContainer {
  & .socialMedialinks {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    margin: 20px 0 0 0;

    @media (--viewportSmall) {
      grid-template-columns: repeat(2, 1fr);
    }

    & > li {
      border: solid 1px var(--matterColorNegative);
      border-radius: 10px;
      padding: 15px 20px;
      cursor: pointer;

      & > svg {
        margin-right: 10px;
      }

      & > button {
        display: flex;
        align-items: center;

        & svg {
          margin-right: 18px;
          width: 42px;
          height: 42px;
        }
      }
    }
  }
}

.shareTitle {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  font-weight: 600;
  font-size: 18px;
}
